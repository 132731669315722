body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /*   background-image: url("/pattern.jpg") !important;
    background-repeat: repeat; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Bayer styles*/
body {
  /* background: #fafafa !important; */
  /*   background: #0090c5 !important; */
}
#main {
  margin: 10px !important;
  display: block;
}

/*Nav*/
header > .menu {
  background: #1c4257 !important;
  border: 0px !important;
}

/*Nav items*/
header > .menu > .item {
  color: white !important;
  border: 0px !important;
  font-weight: 400 !important;
}
header > .menu > .right > .item {
  color: white !important;
  border: 0px !important;
}
/*Subnav*/
#main > .segments {
  /* display: none !important; */
}

/*All segments*/
.ui.segment {
  border: 0px !important;
}

/**EXPERIMENTAL ++ ***/
body {
  background: #fafafa !important;
  background-size: cover !important;
}

header > .menu {
  height: 48px !important;
  background: #1c4257 !important;
}

.ui.progress.indicator {
  border-radius: 0px;
  margin-top: 5px !important;
  margin-bottom: 12px !important;
}

.ui.progress.indicator .bar {
  border-radius: 0px;
  height: 20px !important;
  background: #48a7f3 !important;
}

@keyframes waitingPulse {
  from {
    background-color: white;
  }
  50% {
    background-color: #e3310049;
  }
  to {
    background-color: white;
  }
}

@keyframes startedPulse {
  from {
    background-color: white;
  }
  50% {
    background-color: #ffb51577;
  }
  to {
    background-color: white;
  }
}

@keyframes redPulse {
  from {
    background-color: #962e12;
    -webkit-box-shadow: 0 0 0px #333;
  }
  50% {
    background-color: #e33100;
    -webkit-box-shadow: 0 0 18px #e33100;
  }
  to {
    background-color: #962e12;
    -webkit-box-shadow: 0 0 0px #333;
  }
}

@keyframes orangellowPulse {
  from {
    background-color: #fc9200;
    -webkit-box-shadow: 0 0 0px #333;
  }
  50% {
    background-color: #ffb515;
    -webkit-box-shadow: 0 0 18px #ffb515;
  }
  to {
    background-color: #fc9200;
    -webkit-box-shadow: 0 0 0px #333;
  }
}

div.red {
  color: white !important;
  background-color: #962e12 !important;
  animation-iteration-count: infinite !important;
}

div.orangellow {
  color: white !important;
  background-color: #fc9200 !important;
  animation-iteration-count: infinite !important;
}

.red.mtto.radioactive {
  animation-name: redPulse;
  animation-duration: 1s;
}
.orangellow.mtto.radioactive {
  animation-name: orangellowPulse;
  animation-duration: 1s;
}

.overview.mtto.waiting {
  animation-name: waitingPulse;
  animation-duration: 1s;
  animation-iteration-count: infinite !important;
}

.overview.mtto.started {
  animation-name: startedPulse;
  animation-duration: 1s;
  animation-iteration-count: infinite !important;
}

.mtto-alert .header {
  color: red !important;
}
.mtto-alert .content {
  color: red !important;
}
.mtto-alert .actions .button {
  background-color: #a20e0e !important;
}

.no-overflow .dx-datagrid-rowsview.dx-datagrid-nowrap {
  overflow: visible !important;
}

.no-overflow .dx-cell-focus-disabled.dx-master-detail-cell {
  overflow: visible !important;
}

.no-overflow .dx-datagrid-rowsview.dx-datagrid-nowrap {
  overflow: visible !important;
}
.no-overflow .dx-datagrid-rowsview {
  overflow: visible !important;
}

/*New time selector component*/
.dx-datetime-only > .dx-collection > .dx-item:first-child {
  display: none !important;
}

.dx-datetime-only + div .dx-button-today {
  display: none !important;
}

.dx-field-item-label.dx-field-item-label-location-left {
  width: 60% !important;
}
.dx-field-item-label.dx-field-item-label-location-left > span {
  white-space: normal !important;
  word-break: normal !important;
  width: 100% !important;
}
