.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dx-updated-value input{
  background-color:beige!important;
}

.ui.button.recognize{
  margin:5px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td:not(.top-cell) {
  vertical-align: middle !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td.top-cell{
  vertical-align: top!important;
}

/*Configuration tabs*/
.configuration-tabs{
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top:4vh;
}
.configuration-tabs>.ui.fluid.menu{
  overflow-x: scroll;
}

.configuration-tabs .active.tab{
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15)!important;
}


/*stackedbar*/
.sb-flex-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 40px;
}
.sb-flex-container > div{
  border-left: #ddd 1px solid;
  min-width: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sb-flex-container > div:first-child{
  min-width: 50px;
}
.sb-flex-container > div:last-child{
  min-width: 50px;
}
/* .sb-flex-container > div:first-child{
  border-left: #ccc 1px solid;
}
.sb-flex-container > div:last-child{
  border-right: #ccc 1px solid;
} */
.sb-flex-container > div {
  width: 100%;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  color:#aaa;
  padding-left:5px;
  padding-top:5px;

}

.drag-drop-zone i {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.drag-drop-zone {
  display: table;
  border: 4px dashed white;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  font-size: 1.3em;
  transition: border 0.4s, color 0.4s;
}
.drag-drop-zone.hover {
  border-color: black;
  color: black;
}


/*line*/
.line-row{
  padding:10px 0 0 0!important;
}

/*Bullet pareto*/
.dx-row>td.bullet.pareto{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

/*Dynamic layout*/
.CircularProgressbar{
  height: 100%;
}
.CircularProgressbar.PFlex{
  position: absolute;
  padding:20px;
  height: 100%;
}

/*Listado ordenes */

.dx-data-row .dx-cell-modified .dx-highlight-outline::after {
  visibility: hidden;
}

.dx-data-row .dx-cell-modified {
  background-color:beige!important;
}

/*Scrollbars global settings*/
.dx-scrollable-scroll {
  width: 18px !important;
  min-height: 50px !important;
}

.dx-scrollable-scroll-content {
  background-color:gray !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  width: 18px !important;
  background-color: rgba(191, 191, 191, 0.2);
}
