
.validacion{
    color: rgba(0,0,0,.6);
    font-weight : 700;
    display: inline;
    font-size: 0.85rem;
    padding: 0 0.5rem;
}

.field-validacion{
    float: right;
    border-radius: 5px;
    margin-right: 0px!important;
    display: inline;
    /*width: 48%;*/
}

.dx-switch {
    width: 50px!important;
}

/*
.ui.form .fields>.field {
    padding-right: 0rem!important; 
}*/

.ui.form .fields:last-child .field {
    margin-bottom: 1em!important;
}