body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /*   background-image: url("/pattern.jpg") !important;
    background-repeat: repeat; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Bayer styles*/
body {
  /* background: #fafafa !important; */
  /*   background: #0090c5 !important; */
}
#main {
  margin: 10px !important;
  display: block;
}

/*Nav*/
header > .menu {
  background: #1c4257 !important;
  border: 0px !important;
}

/*Nav items*/
header > .menu > .item {
  color: white !important;
  border: 0px !important;
  font-weight: 400 !important;
}
header > .menu > .right > .item {
  color: white !important;
  border: 0px !important;
}
/*Subnav*/
#main > .segments {
  /* display: none !important; */
}

/*All segments*/
.ui.segment {
  border: 0px !important;
}

/**EXPERIMENTAL ++ ***/
body {
  background: #fafafa !important;
  background-size: cover !important;
}

header > .menu {
  height: 48px !important;
  background: #1c4257 !important;
}

.ui.progress.indicator {
  border-radius: 0px;
  margin-top: 5px !important;
  margin-bottom: 12px !important;
}

.ui.progress.indicator .bar {
  border-radius: 0px;
  height: 20px !important;
  background: #48a7f3 !important;
}

@-webkit-keyframes waitingPulse {
  from {
    background-color: white;
  }
  50% {
    background-color: #e3310049;
  }
  to {
    background-color: white;
  }
}

@keyframes waitingPulse {
  from {
    background-color: white;
  }
  50% {
    background-color: #e3310049;
  }
  to {
    background-color: white;
  }
}

@-webkit-keyframes startedPulse {
  from {
    background-color: white;
  }
  50% {
    background-color: #ffb51577;
  }
  to {
    background-color: white;
  }
}

@keyframes startedPulse {
  from {
    background-color: white;
  }
  50% {
    background-color: #ffb51577;
  }
  to {
    background-color: white;
  }
}

@-webkit-keyframes redPulse {
  from {
    background-color: #962e12;
    -webkit-box-shadow: 0 0 0px #333;
  }
  50% {
    background-color: #e33100;
    -webkit-box-shadow: 0 0 18px #e33100;
  }
  to {
    background-color: #962e12;
    -webkit-box-shadow: 0 0 0px #333;
  }
}

@keyframes redPulse {
  from {
    background-color: #962e12;
    -webkit-box-shadow: 0 0 0px #333;
  }
  50% {
    background-color: #e33100;
    -webkit-box-shadow: 0 0 18px #e33100;
  }
  to {
    background-color: #962e12;
    -webkit-box-shadow: 0 0 0px #333;
  }
}

@-webkit-keyframes orangellowPulse {
  from {
    background-color: #fc9200;
    -webkit-box-shadow: 0 0 0px #333;
  }
  50% {
    background-color: #ffb515;
    -webkit-box-shadow: 0 0 18px #ffb515;
  }
  to {
    background-color: #fc9200;
    -webkit-box-shadow: 0 0 0px #333;
  }
}

@keyframes orangellowPulse {
  from {
    background-color: #fc9200;
    -webkit-box-shadow: 0 0 0px #333;
  }
  50% {
    background-color: #ffb515;
    -webkit-box-shadow: 0 0 18px #ffb515;
  }
  to {
    background-color: #fc9200;
    -webkit-box-shadow: 0 0 0px #333;
  }
}

div.red {
  color: white !important;
  background-color: #962e12 !important;
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
}

div.orangellow {
  color: white !important;
  background-color: #fc9200 !important;
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
}

.red.mtto.radioactive {
  -webkit-animation-name: redPulse;
          animation-name: redPulse;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.orangellow.mtto.radioactive {
  -webkit-animation-name: orangellowPulse;
          animation-name: orangellowPulse;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.overview.mtto.waiting {
  -webkit-animation-name: waitingPulse;
          animation-name: waitingPulse;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
}

.overview.mtto.started {
  -webkit-animation-name: startedPulse;
          animation-name: startedPulse;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
}

.mtto-alert .header {
  color: red !important;
}
.mtto-alert .content {
  color: red !important;
}
.mtto-alert .actions .button {
  background-color: #a20e0e !important;
}

.no-overflow .dx-datagrid-rowsview.dx-datagrid-nowrap {
  overflow: visible !important;
}

.no-overflow .dx-cell-focus-disabled.dx-master-detail-cell {
  overflow: visible !important;
}

.no-overflow .dx-datagrid-rowsview.dx-datagrid-nowrap {
  overflow: visible !important;
}
.no-overflow .dx-datagrid-rowsview {
  overflow: visible !important;
}

/*New time selector component*/
.dx-datetime-only > .dx-collection > .dx-item:first-child {
  display: none !important;
}

.dx-datetime-only + div .dx-button-today {
  display: none !important;
}

.dx-field-item-label.dx-field-item-label-location-left {
  width: 60% !important;
}
.dx-field-item-label.dx-field-item-label-location-left > span {
  white-space: normal !important;
  word-break: normal !important;
  width: 100% !important;
}


.validacion{
    color: rgba(0,0,0,.6);
    font-weight : 700;
    display: inline;
    font-size: 0.85rem;
    padding: 0 0.5rem;
}

.field-validacion{
    float: right;
    border-radius: 5px;
    margin-right: 0px!important;
    display: inline;
    /*width: 48%;*/
}

.dx-switch {
    width: 50px!important;
}

/*
.ui.form .fields>.field {
    padding-right: 0rem!important; 
}*/

.ui.form .fields:last-child .field {
    margin-bottom: 1em!important;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dx-updated-value input{
  background-color:beige!important;
}

.ui.button.recognize{
  margin:5px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td:not(.top-cell) {
  vertical-align: middle !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td.top-cell{
  vertical-align: top!important;
}

/*Configuration tabs*/
.configuration-tabs{
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top:4vh;
}
.configuration-tabs>.ui.fluid.menu{
  overflow-x: scroll;
}

.configuration-tabs .active.tab{
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15)!important;
}


/*stackedbar*/
.sb-flex-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 40px;
}
.sb-flex-container > div{
  border-left: #ddd 1px solid;
  min-width: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sb-flex-container > div:first-child{
  min-width: 50px;
}
.sb-flex-container > div:last-child{
  min-width: 50px;
}
/* .sb-flex-container > div:first-child{
  border-left: #ccc 1px solid;
}
.sb-flex-container > div:last-child{
  border-right: #ccc 1px solid;
} */
.sb-flex-container > div {
  width: 100%;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  color:#aaa;
  padding-left:5px;
  padding-top:5px;

}

.drag-drop-zone i {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.drag-drop-zone {
  display: table;
  border: 4px dashed white;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  font-size: 1.3em;
  transition: border 0.4s, color 0.4s;
}
.drag-drop-zone.hover {
  border-color: black;
  color: black;
}


/*line*/
.line-row{
  padding:10px 0 0 0!important;
}

/*Bullet pareto*/
.dx-row>td.bullet.pareto{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

/*Dynamic layout*/
.CircularProgressbar{
  height: 100%;
}
.CircularProgressbar.PFlex{
  position: absolute;
  padding:20px;
  height: 100%;
}

/*Listado ordenes */

.dx-data-row .dx-cell-modified .dx-highlight-outline::after {
  visibility: hidden;
}

.dx-data-row .dx-cell-modified {
  background-color:beige!important;
}

/*Scrollbars global settings*/
.dx-scrollable-scroll {
  width: 18px !important;
  min-height: 50px !important;
}

.dx-scrollable-scroll-content {
  background-color:gray !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  width: 18px !important;
  background-color: rgba(191, 191, 191, 0.2);
}

